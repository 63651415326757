<template>
  <Page
    has-actions
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
    :extra-subtitle="extra"
    :loading="tickets_loading"
  >
    <b-loading
      v-if="tickets_loading && !created"
      :is-full-page="false"
      :active="true"
    />

    <div v-if="created" slot="actions">
      <div class="field has-addons">
        <div class="is-flex mr-2">
          <b-button
            v-if="$device.mobile"
            size="is-small"
            rounded
            @click="showMobile()"
          >
            <b-icon icon="magnify" size="is-small" />
          </b-button>
          <b-input
            ref="search"
            v-model="columns[2].input"
            :disabled="tickets_loading"
            :placeholder="placeholders.number['6']"
            :size="$device.mobile ? 'is-small' : ''"
            :class="{ expand: searchFocused, 'hide-search': $device.mobile }"
            icon="magnify"
            rounded
            @input="getTickets()"
            @blur="hideMobile()"
          />
        </div>
        <b-dropdown
          ref="dropdown"
          aria-role="list"
          position="is-bottom-left"
          :close-on-click="false"
          scrollable
          max-height="400px"
          append-to-body
        >
          <div slot="trigger">
            <b-button
              icon-left="dots-vertical"
              :size="$device.mobile ? 'is-small' : ''"
              type="is-default"
              rounded
            />
          </div>

          <b-dropdown-item
            :disabled="ticketOpen"
            aria-role="listitem"
            class="pr-3"
            @click="showColumnsTogle = !showColumnsTogle"
          >
            <b-icon icon="view-column" size="is-small" />
            {{ $tc("column", 2) }}
            <b-icon
              class="is-pulled-right"
              :icon="!showColumnsTogle ? 'chevron-down' : 'chevron-up'"
              size="is-small"
            />
          </b-dropdown-item>
          <template v-if="showColumnsTogle && !ticketOpen">
            <template v-for="item in columns">
              <b-dropdown-item
                v-if="!item.hidden"
                :key="item.key"
                aria-role="listitem"
                :disabled="item.disabled"
                :focusable="false"
                custom
              >
                <b-switch
                  v-model="item.visible"
                  :disabled="item.disabled"
                  type="is-success"
                >
                  {{ item.label }}
                </b-switch>
              </b-dropdown-item>
            </template>
          </template>
        </b-dropdown>
      </div>
    </div>

    <Card
      v-if="created"
      :content-class="ticketOpen ? 'shrink c-table' : 'c-table'"
    >
      <template>
        <div class="header-container">
          <div />
          <div class="is-flex">
            <b-field position="is-right" class="mr-4 mb-0">
              <p v-for="item in assignements" :key="item.value" class="control">
                <b-button
                  :disabled="tickets_loading"
                  size="is-small"
                  :type="item.value == selected_assignement ? 'is-primary' : ''"
                  @click="
                    () => {
                      selected_assignement = item.value;
                      setFilter(item, 'agent');
                      getTickets();
                    }
                  "
                >
                  {{ item.text }}
                </b-button>
              </p>
            </b-field>
            <b-field position="is-right" class="mb-0">
              <p v-for="item in statuses" :key="item.value" class="control">
                <b-button
                  :disabled="tickets_loading"
                  size="is-small"
                  :type="item.value == selected_status ? 'is-primary' : ''"
                  @click="
                    () => {
                      selected_status = item.value;
                      setFilter(item, 'tstatus');
                      getTickets();
                    }
                  "
                >
                  {{ item.text }}
                </b-button>
              </p>
            </b-field>
          </div>
        </div>
        <hr class="my-0" />
      </template>
      <div class="columns is-mobile is-variable m-0">
        <div
          class="column tickets-container list p-0"
          :class="ticketOpen ? 'is-3' : 'is-12'"
        >
          <b-table
            :data="items"
            :loading="tickets_loading"
            hoverable
            mobile-cards
            backend-sorting
            :default-sort="[sortingPriority[0].field, sortingPriority[0].order]"
            :sort-multiple="sortingMultiple"
            :sort-multiple-data="sortingPriority"
            sort-multiple-key="shiftKey"
            :show-header="!ticketOpen"
            :total="total"
            :row-class="
              (row, index) => [
                row.seen ? '' : 'has-text-weight-semibold',
                ticketOpen && row.id == openTicketId ? 'bg-selected' : '',
              ]
            "
            @sort="sortPressed"
            @sorting-priority-removed="sortingPriorityRemoved"
          >
            <template v-for="head in columns">
              <b-table-column
                :key="head.key"
                :visible="head.visible"
                :field="head.key"
                :label="head.label"
                :width="head.width"
                :centered="head.centered"
                :sortable="head.sortable"
                :searchable="table.filterable && head.filterable"
                v-bind="head"
                header-class="sticky-header"
              >
                <template v-if="head.filterable" slot="searchable">
                  <template v-if="head.type == 'date'">
                    <b-datepicker
                      v-model="head.input"
                      rounded
                      :placeholder="placeholders.date.day"
                      size="is-small"
                      editable
                      :range="head.filter.key == 'dr'"
                      :min-date="new Date('01/01/2013')"
                      :max-date="new Date()"
                      @input="getTickets()"
                    />
                    <span
                      class="custom-date-filter"
                      @click="showDrop(head.key)"
                    >
                      <b-icon icon="filter" size="is-small" />
                    </span>
                    <b-dropdown
                      :ref="`filter_drop_${head.key}`"
                      aria-role="list"
                      class="is-flex"
                    >
                      <b-dropdown-item
                        v-for="operator in head.operators"
                        :key="operator.label"
                        aria-role="listitem"
                        @click="changeFilter(head.key, operator)"
                      >
                        {{ operator.label }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                  <template v-else-if="head.type == 'select'">
                    <b-select-validation
                      v-model="head.input"
                      rounded
                      size="is-small"
                      hide-details
                      :expanded="true"
                      @change="getTickets()"
                    >
                      <template>
                        <option
                          v-for="opt in head.filters"
                          :key="opt.id"
                          :value="opt.id"
                        >
                          {{ opt.label }}
                        </option>
                      </template>
                    </b-select-validation>
                  </template>
                  <template v-else-if="head.type == 'boolean'">
                    <b-select-validation
                      v-model="head.input"
                      rounded
                      expanded
                      size="is-small"
                      @change="getTickets()"
                    >
                      <template>
                        <option :value="true">
                          {{ $t("yes") }}
                        </option>
                        <option :value="false">
                          {{ $t("no") }}
                        </option>
                      </template>
                    </b-select-validation>
                  </template>
                  <template
                    v-else-if="
                      head.type == 'number' ||
                      head.type == 'text' ||
                      head.type == 'custom'
                    "
                  >
                    <b-input
                      v-model="head.input"
                      :placeholder="head.filter.label"
                      rounded
                      hide-details
                      size="is-small"
                      icon-right="filter"
                      icon-right-clickable
                      @icon-right-click="showDrop(head.key)"
                      @input="getTickets()"
                    />
                    <b-dropdown
                      :ref="`filter_drop_${head.key}`"
                      aria-role="list"
                      class="is-flex"
                    >
                      <b-dropdown-item
                        v-for="operator in head.operators"
                        :key="operator.label"
                        aria-role="listitem"
                        @click="changeFilter(head.key, operator)"
                      >
                        {{ operator.label }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                  <template v-else-if="head.type == 'autocomplete'">
                    <b-autocomplete
                      v-model="head.input"
                      :placeholder="$t('choose')"
                      rounded
                      hide-details
                      size="is-small"
                      :data="getAutocompleteFilters(head.filters, head.input)"
                      field="label"
                      @select="getTickets()"
                      clearable
                    >
                      <template v-if="(head.key = 'ttags')" slot-scope="props">
                        <b-tag
                          :style="{
                            'background-color': props.option.background,
                            color: props.option.color,
                            border: '1px solid' + props.option.border,
                          }"
                        >
                          {{ props.option.label }}
                        </b-tag>
                      </template>
                    </b-autocomplete>
                  </template>
                </template>

                <!-- site -->
                <template v-if="head.key == 'site'" v-slot="props">
                  <img
                    height="20"
                    width="20"
                    :src="'/img/sites/' + props.row.site.id + '.svg'"
                  />
                </template>

                <!-- Warehouse -->
                <template v-else-if="head.key === 'warehouse'" v-slot="props">
                  <img
                    height="18"
                    width="18"
                    :src="
                      '/img/flags/' + props.row.warehouse.country.iso + '.svg'
                    "
                  />
                </template>

                <!-- Ticket -->
                <template v-else-if="head.key === 'ticket'" v-slot="props">
                  <a
                    href="javascript:;"
                    sortable
                    @click="openTicket(props.row.id)"
                    >{{ props.row.id }}</a
                  >
                </template>

                <!-- User -->
                <template v-else-if="head.key === 'user'" v-slot="props">
                  <router-link :to="`/users/${props.row.user.id}`">{{
                    props.row.user &&
                    props.row.user.fname + " " + props.row.user.lname
                  }}</router-link>
                </template>

                <!-- Email -->
                <template v-else-if="head.key === 'uemail'" v-slot="props">
                  {{ props.row.user && props.row.user.email }}
                </template>

                <!-- subject -->
                <template v-else-if="head.key === 'tsubject'" v-slot="props">
                  <div class="is-flex is-align-items-center">
                    <b-tag
                      v-if="!props.row.seen"
                      type="is-primary"
                      size="is-small"
                      class="has-text-weight-normal mr-1"
                    >
                      {{ props.row.count.unread }}
                    </b-tag>
                    <b-icon
                      v-if="props.row.replied"
                      icon="reply"
                      type="is-info"
                      size="is-small"
                      class="mr-2"
                    ></b-icon>
                    <span
                      :class="props.row.seen && !props.row.replied ? 'ml-5' : ''"
                      >{{ props.row.subject.label }}</span
                    >
                  </div>
                </template>

                <!-- last_thread_at  -->
                <template v-else-if="head.key === 'tdate'" v-slot="props">
                  <b-tooltip position="is-bottom" type="is-primary is-light">
                    <span>
                      {{
                        (props.row.last_thread_at &&
                          props.row.last_thread_at.ago) ||
                        "-"
                      }}
                    </span>
                    <template v-slot:content>
                      <span>
                        {{
                          (props.row.last_thread_at &&
                            props.row.last_thread_at.formatted) ||
                          "-"
                        }}
                        <span class="has-text-grey">{{
                          props.row.last_thread_at &&
                          props.row.last_thread_at.time
                        }}</span>
                      </span>
                    </template>
                  </b-tooltip>
                </template>

                <!-- tcreated  -->
                <template v-else-if="head.key === 'tcreated'" v-slot="props">
                  <b-tooltip position="is-bottom" type="is-primary is-light">
                    <span>
                      {{
                        (props.row.created_at && props.row.created_at.ago) ||
                        "-"
                      }}
                    </span>
                    <template v-slot:content>
                      <span>
                        {{
                          (props.row.created_at &&
                            props.row.created_at.formatted) ||
                          "-"
                        }}
                        <span class="has-text-grey">{{
                          props.row.created_at && props.row.created_at.time
                        }}</span>
                      </span>
                    </template>
                  </b-tooltip>
                </template>

                <!-- status -->
                <template v-else-if="head.key === 'tstatus'" v-slot="props">
                  <b-tag :type="getColor(props.row.status.color)">
                    {{ (props.row.status && props.row.status.label) || "-" }}
                  </b-tag>
                </template>

                <!-- priority -->
                <template v-else-if="head.key === 'tpriority'" v-slot="props">
                  <b-icon
                    icon="circle"
                    size="is-small"
                    :type="getColor(props.row.priority.color)"
                  />
                  {{ props.row.priority.label || "-" }}
                </template>

                <!-- ttags -->
                <template v-else-if="head.key === 'ttags'" v-slot="props">
                  <template v-if="props.row.tags.length">
                    <b-tag
                      v-for="tag in props.row.tags"
                      :key="tag.tag"
                      class="mr-2"
                      :style="{
                        'background-color': tag.background,
                        color: tag.color,
                        border: '1px solid' + tag.border,
                      }"
                    >
                      {{ tag.tag }}
                    </b-tag>
                  </template>
                  <span v-else>-</span>
                </template>

                <!-- agent -->
                <template v-else-if="head.key == 'agent'" v-slot="props">
                  {{
                    props.row.agent
                      ? props.row.agent.fname + " " + props.row.agent.lname
                      : "-"
                  }}
                </template>

                <!-- Order -->
                <template v-else-if="head.key === 'order'" v-slot="props">
                  <a
                    href="javascript:;"
                    @click="
                      openDrawer('orderForm', {
                        key: props.row.order.id,
                        index: props.index,
                        order_id: props.row.order.id,
                        user_id: props.row.user.id,
                        site_id: props.row.site.id,
                        warehouse_id: warehouse,
                        mp_id: props.row.mp_id,
                        warehouses: warehouses,
                      })
                    "
                    >{{ props.row.order.id }}</a
                  >
                </template>
                <!-- shrinked details -->
                <template v-else-if="head.key === 'shrinked'" v-slot="props">
                  <ul class="details" @click="openTicket(props.row.id)">
                    <li>
                      <div class="wrap">
                        <img
                          height="20"
                          width="20"
                          class="mr-2"
                          :src="'/img/sites/' + props.row.site.id + '.svg'"
                        />
                        <!-- <a
                          href="javascript:;"
                          sortable
                          @click="openTicket(props.row.id)"
                          >{{ props.row.id }}</a
                        > -->
                        {{ props.row.id }}
                      </div>

                      <b-tag
                        :type="getColor(props.row.status.color)"
                        size="is-small"
                      >
                        {{
                          (props.row.status && props.row.status.label) || "-"
                        }}
                      </b-tag>
                    </li>
                    <li>
                      <!-- <router-link
                        :to="`/users/${props.row.user.id}`"
                        target="_blank"
                        >{{
                          props.row.user &&
                          props.row.user.fname + " " + props.row.user.lname
                        }}</router-link
                      > -->

                      {{
                        props.row.user &&
                        props.row.user.fname + " " + props.row.user.lname
                      }}

                      <b-tooltip position="is-left" type="is-primary is-light">
                        <span>
                          {{
                            (props.row.created_at &&
                              props.row.created_at.ago) ||
                            "-"
                          }}
                        </span>
                        <template v-slot:content>
                          <span>
                            {{
                              (props.row.created_at &&
                                props.row.created_at.formatted) ||
                              "-"
                            }}
                            <span class="has-text-grey">{{
                              props.row.created_at && props.row.created_at.time
                            }}</span>
                          </span>
                        </template>
                      </b-tooltip>
                    </li>
                  </ul>
                </template>

                <template v-else v-slot="props">
                  <b-icon
                    v-if="typeof props.row[head.key] === 'boolean'"
                    custom-size="mdi-18px"
                    :icon="props.row[head.key] ? 'check' : 'close'"
                    :type="props.row[head.key] ? 'is-success' : 'is-danger'"
                  />
                  <template v-else>
                    {{ props.row[head.key] || "-" }}
                  </template>
                </template>
              </b-table-column>
            </template>
            <EmptyBlock slot="empty" icon="ticket-outline" />
          </b-table>
          <Pagination
            v-if="!tickets_loading"
            :per-page="perPage"
            :per-pages="perPages"
            :total="total"
            :current-page="currentPage"
            @update-per-page="(val) => (perPage = val)"
            @update-current-page="(val) => (currentPage = val)"
          />
        </div>
        <div
          v-if="ticketOpen"
          class="column p-0"
          :class="ticketOpen ? 'is-9' : 'is-0'"
        >
          <Ticket
            :options="{
              ticket_id: openTicketId,
              agents: agents,
              subjects: filters.subjects,
              statuses: filters.statuses,
              tags: filters.tags,
              priorities: filters.priorities,
            }"
            @close="closeTicket"
          />
        </div>
      </div>
    </Card>
  </Page>
</template>
  
  <script>
import moment from "moment";
import debounce from "debounce";
import Ticket from "@/components/modules/Cs/Ticket";

export default {
  components: {
    Ticket,
  },
  data() {
    return {
      ticketOpen: false,
      openTicketId: null,
      searchFocused: false,
      showColumnsTogle: false,
      sortingPriority: [],
      sortingMultiple: null,
      singleSorting: {},
      currentPage: 0,
      perPage: 0,
      perPages: [],
      total: 0,
      tickets_loading: true,
      warehouse: null,
      warehouses: [],
      agents: [],
      filters: {},
      table: {},
      columns: [],
      visibleColumns: [],
      created: false,

      selected_assignement: "all",
      assignements: [
        {
          text: this.$t("all"),
          value: "all",
          filter: { key: "et", label: "is =" },
        },
        {
          text: this.$t("my_tickets"),
          value: "mine",
          filter: { key: "et", label: "is =" },
        },
      ],
      selected_status: "ongoing",
      statuses: [
        {
          text: this.$tc("ongoing"),
          value: "ongoing",
          filter: { key: "et", label: "is =" },
          input: "",
        },
        {
          text: this.$tc("solved"),
          value: "solved",
          filter: { key: "et", label: "is =" },
          input: "4",
        },
      ],
      items: [],
      extra: [],
      dates: [],
      date_filter: {},
      init: false,
    };
  },
  watch: {
    currentPage() {
      if (!this.tickets_loading) this.getTickets();
    },
    perPage() {
      if (!this.tickets_loading) this.getTickets();
    },
    ticketOpen(newVal) {
      if (newVal) {
        for (let i = 0; i < this.columns.length; i++) {
          this.columns[i].visible = false;
        }
        let index = this.columns.findIndex((c) => c.hidden == true);
        this.columns[index].visible = true;
      } else {
        for (let i = 0; i < this.columns.length; i++) {
          if (this.visibleColumns.includes(this.columns[i].key)) {
            this.columns[i].visible = true;
          } else {
            this.columns[i].visible = false;
          }
        }
        let index = this.columns.findIndex((h) => h.hidden == true);
        this.columns[index].visible = false;
      }
    },
  },
  created() {
    this.updateTitle(this.$i18n.tc("ticket", 2));
    if (!this.created) this.getTickets();
  },
  mounted() {
    if (this.$route.params.ticket_id) {
      let id = this.$route.params.ticket_id;
      setTimeout(() => {
        this.openTicket(id);
      }, 1500);
    }

    this.$bus.$on("update-seen", (data) => {
      let index = this.items.findIndex((t) => t.id == data.id);
      this.items[index].seen = true;
      this.items[index].count.unread = 0;
    });

    this.$bus.$on("update-tickets-list", (params) => {
      let index = this.items.findIndex((item) => item.id === Number(params.id));
      this.items[index] = params.ticket;

      let item = this.items[index];
      this.$set(this.items, index, item);
    });

    this.$bus.$on("update-selected-ticket", (params) => {
      this.openTicketId = params.id;
    });

    document.addEventListener("keydown", this.handleKeydown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeydown);
  },
  destroyed() {
    this.$bus.$off("update-seen");
    this.$bus.$off("update-tickets-list");
    this.$bus.$off("update-selected-ticket");
  },
  methods: {
    handleKeydown(event) {
      if (event.key === "Escape" || event.keyCode === 27) {
        this.closeTicket();
      }
    },
    showMobile() {
      if (this.$device.mobile) {
        this.searchFocused = true;
        this.$refs.search.focus();
      }
    },
    hideMobile() {
      if (this.$device.mobile) {
        this.searchFocused = false;
      }
    },
    showDrop(field) {
      this.$refs[`filter_drop_${field}`][0].toggle();
    },
    initFilters() {
      for (let i = 0; i < this.columns.length; i++) {
        this.columns[i]["filter"] =
          this.columns[i] &&
          this.columns[i].operators &&
          this.columns[i].operators.length
            ? this.columns[i].operators[0]
            : null;
      }
    },
    openTicket(id) {
      this.openTicketId = id;

      if (this.ticketOpen) {
        this.$bus.$emit("update-ticket", { ticket_id: id });
      } else this.ticketOpen = true;
    },
    closeTicket() {
      this.openTicketId = null;
      this.ticketOpen = false;
      this.updateTitle(this.$i18n.tc("ticket", 2));
    },
    changeDates(dates) {
      this.date_filter.from = dates.date.from;
      this.date_filter.to = dates.date.to;
      if (!this.init) {
        this.getTickets();
      } else {
        this.init = false;
      }
    },
    changeFilter(field, value) {
      let index = this.columns.findIndex((h) => h.key == field);
      this.columns[index].filterable = false;
      this.columns[index].filter = value;
      this.columns[index].filterable = true;
      if (this.columns[index].input) this.getTickets();
      // else this.columns[index].input = "";
    },
    sortPressed(field, order, event) {
      if (event["shiftKey"]) {
        let existingPriority = this.sortingPriority.filter(
          (i) => i.field === field
        )[0];
        if (existingPriority) {
          existingPriority.order =
            existingPriority.order === "desc" ? "asc" : "desc";
        } else {
          if (this.singleSorting.field)
            this.sortingPriority.push(this.singleSorting);
          this.sortingPriority.push({ field, order });
        }
        this.singleSorting = {};
      } else {
        this.sortingPriority = [{ field, order }]; // [{field, order}]
        // this.singleSorting = { field, order };
      }
      this.getTickets();
    },
    sortingPriorityRemoved(value) {
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== value
      );
      this.getTickets();
    },
    setFilter(item, filter) {
      let index = this.columns.findIndex((p) => p.key == filter);
      this.columns[index].input =
        filter == "tstatus"
          ? item.input
          : item.value == "mine"
          ? this.columns[index].filters[0].id
          : "";
      this.columns[index]["filter"] = item.filter;
    },
    getAutocompleteFilters(filters, input) {
      return input
        ? filters.filter((option) => {
            return (
              option.label
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            );
          })
        : filters;
    },
    getTickets: debounce(function (params = null) {
      let api_params = "";
      if (this.currentPage && this.perPage)
        api_params = `?pagination=${this.currentPage}:${this.perPage}`;

      if (this.sortingPriority.length > 1) {
        let sortArr = [];
        for (let i = 0; i < this.sortingPriority.length; i++) {
          const sort = this.sortingPriority[i];
          sortArr.push(`${sort.field}:${sort.order}`);
        }
        api_params += `&sorting=${sortArr.join(",")}`;
      } else if (this.sortingPriority.length == 1) {
        api_params += `&sorting=${this.sortingPriority[0].field}:${this.sortingPriority[0].order}`;
      } else if (this.singleSorting.field) {
        api_params += `&sorting=${this.singleSorting.field}:${this.singleSorting.order}`;
      }

      let searchArr = [];

      if (this.table && this.columns) {
        for (let i = 0; i < this.columns.length; i++) {
          const value = this.columns[i];
          if (value.key == "tstatus") {
            this.selected_status =
              value.input == 4 ? "solved" : value.input ? "" : "ongoing";
          } else if (value.key == "agent") {
            this.selected_assignement =
              value.input == value.filters[0].id
                ? "mine"
                : value.input
                ? ""
                : "all";
          }
          if (
            ["user", "uemail"].includes(value.key) &&
            value.input &&
            value.input.length != 0 &&
            value.input.length < 3
          )
            return;
          else if (value.input || typeof value.input == "boolean") {
            if (value.key == "ttags") {
              let index = this.columns[i].filters.findIndex(
                (h) => h.label == value.input
              );
              value.autocompleteInput = this.columns[i].filters[index].id;
            }
            let obj = {
              c: value.key,
              v: ["tcreated", "tdate"].includes(value.key)
                ? moment(value.input).format("YYYY-MM-DD")
                : value.key == "ttags"
                ? value.autocompleteInput
                : value.input,
            };
            if (value.filter) obj.o = value.filter.key;
            searchArr.push(obj);
          }
        }
      }

      if (this.$route.params.ticket_id && !this.created) {
        api_params = "?filters=ticket:et:" + this.$route.params.ticket_id;
      }

      if (searchArr.length) {
        api_params += this.$root.formatFilter(searchArr);
      }

      this.tickets_loading = true;

      this.$axios
        .get("tickets" + api_params)
        .then((res) => {
          this.table = res.data.table;
          this.items = res.data.list;
          this.filters = res.data.filters;
          this.agents = res.data.filters.agents;
          this.warehouses = res.data.filters.warehouses;

          if (!this.warehouse)
            this.warehouse = this.warehouses[0] && this.warehouses[0].id;

          this.sortingPriority = this.table.sorting.priority.map((item) => {
            return { field: item.key, order: item.order };
          });

          this.sortingMultiple = this.table.sorting.multiple;
          this.singleSorting = this.sortingPriority[0];
          this.currentPage = this.table.pagination.current;
          this.perPage = this.table.pagination.limit;
          this.perPages = this.table.pagination.limits;
          this.total = this.table.pagination.total;

          if (!this.created) {
            this.columns = this.table.columns;
            let index = this.columns.findIndex((h) => h.key == "shrinked");
            this.columns[index].hidden = true;
            let visibleCol = this.columns.filter((item) => {
              return item.visible;
            });
            this.visibleColumns = visibleCol.map((item) => {
              return item.key;
            });
            this.initFilters();
          }

          if (this.$route.params.ticket_id && !this.created) {
            let index = this.columns.findIndex((h) => h.key == "ticket");
            this.columns[index].input = this.$route.params.ticket_id;
          }

          this.created = true;
        })
        .catch((e) => this.clientError(e))
        .finally(() => (this.tickets_loading = false));
    }, 200),
  },
};
</script>
  

  