<template>
  <div v-if="!ticket_loading" class="ticket-drawer" id="ticket">
    <!-- <div class="header">
      <div class="close" @click="$emit('close')">
        <b-icon icon="close" />
      </div>
      <p class="title is-size-4-mobile">
        {{ ticket && ticket.subject && ticket.subject.label }}
        <span class="has-text-grey-light"> #{{ ticket && ticket.id }} </span>
      </p>
    </div> -->
    <!-- <div class="close" @click="$emit('close')">
      <b-icon icon="close" />
    </div> -->
    <div
      v-if="ticket"
      class="ticket columns is-mobile is-multiline is-variable is-5 m-0"
    >
      <div
        class="scrollable column is-9-tablet is-12-mobile p-0 column-separator-right"
      >
        <b-tabs v-model="activeTab" class="mb-0">
          <b-tab-item value="threads">
            <!--class="threads-tab" :style="{
              'height':
                'calc(100vh -' + (show_thread_field ? '485px)' : '320px)'),
            }" -->
            <template #header>
              {{ threads.label }}
              <b-tag
                :type="activeTab == 'threads' ? 'is-primary' : 'is-light'"
                size="is-small"
                class="ml-2"
              >
                {{ threads.list.length }}
              </b-tag>
            </template>
            <ul class="threads">
              <li
                v-for="(msg, index) in threads.list"
                :key="index"
                :class="threadUpdated == index ? 'bg-updated' : null"
              >
                <div class="msg-header">
                  <div class="wrap">
                    <b>{{
                      msg.agent
                        ? msg.agent.signature
                        : ticket.user.fname + " " + ticket.user.lname
                    }}</b>
                    <b-tag
                      v-if="msg.internal.value"
                      type="is-warning"
                      class="ml-2"
                    >
                      {{ msg.internal.label }}
                    </b-tag>
                    <b-icon
                      v-if="msg.seen === false"
                      icon="circle"
                      class="ml-1"
                      size="is-small"
                      type="is-info"
                    />
                  </div>
                  <div class="wrap">
                    <span class="date">
                      {{ msg.created_at.formatted }}
                      {{ msg.created_at.time }}
                    </span>
                    <b-dropdown
                      aria-role="list"
                      position="is-bottom-left"
                      class="ml-2"
                      :disabled="!Object.keys(msg.actions).length"
                    >
                      <div slot="trigger">
                        <b-icon
                          icon="dots-vertical"
                          size="is-small"
                          class="is-clickable"
                        />
                      </div>
                      <b-dropdown-item
                        v-if="msg.actions && msg.actions.update"
                        @click="OpenEditThread(msg)"
                      >
                        <b-icon icon="pencil" size="is-small" />
                        {{ msg.actions.update.label }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="msg.actions && msg.actions.mark_unread"
                        @click="markUnread(msg)"
                      >
                        {{ msg.actions.mark_unread.label }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>

                <div v-if="show_thread_edit !== msg.id" class="msg-body">
                  <span v-html="msg.thread" />
                </div>
                <div v-if="show_thread_edit === msg.id" class="mt-2">
                  <b-field
                    id="msg"
                    ref="thread"
                    class="editor inline mb-0"
                    :disabled="disabled"
                    :message="errors"
                  >
                    <froala
                      ref="editor"
                      id="editor"
                      v-model="editedThread"
                      class="editor ticket-editor"
                      :config="editorConfig"
                      hide-details
                    />
                  </b-field>

                  <ImageUpload
                    class="ticket-uploader small-uploader mt-0"
                    :label="forms.reply.fields.attachment.placeholder"
                    :allow-multiple="true"
                    :images="images"
                    :required="false"
                    :accepted-file-types="
                      forms.reply.fields.attachment.accepted.extensions
                    "
                    imagePreviewHeight="39px"
                    @updateFiles="(files) => (images = files)"
                  />
                  <div class="my-3">
                    <b-button
                      type="is-primary"
                      class="mr-3"
                      :disabled="edit_loading"
                      :loading="edit_loading"
                      tag="input"
                      native-type="submit"
                      :value="$t('edit')"
                      @click="editThread()"
                    >
                      {{ $t("edit") }}
                    </b-button>
                    <b-button
                      :disabled="edit_loading"
                      @click="show_thread_edit = null"
                    >
                      {{ $t("cancel") }}
                    </b-button>
                  </div>
                </div>
                <ul
                  v-if="msg.attachments && msg.attachments.length"
                  class="attachments"
                >
                  <li v-for="(att, i) in msg.attachments" :key="i">
                    <b-icon
                      :icon="
                        att.type === 'image'
                          ? 'file-image-outline'
                          : att.type === 'pdf'
                          ? 'file-pdf-box'
                          : 'file-document-outline'
                      "
                      class="baseline mr-1"
                      type="is-primary"
                    ></b-icon>
                    <a
                      v-if="att.type === 'image'"
                      href="javascript:;"
                      @click="openSlides('views', msg, i)"
                      >{{ att.label }}</a
                    >
                    <a
                      v-else
                      href="javascript:;"
                      @click="download(att, msg.id)"
                      >{{ att.label }}</a
                    >
                    <!-- <a
						v-else-if="att.type === 'pdf' || att.type === 'doc'"
						:href="att.url + att.path + att.label"
						target="_blank"
						>{{ att.label }}</a
					  > -->
                    {{ att.size ? "(" + att.size + ")" : "" }}
                    <span
                      v-if="show_thread_edit !== msg.id"
                      @click="download(att, msg.id)"
                    >
                      <b-icon
                        icon="tray-arrow-down"
                        class="is-clickable ml-1"
                        size="is-small"
                      ></b-icon>
                    </span>
                    <span
                      v-if="show_thread_edit === msg.id"
                      @click="confirmDeleting(att, msg)"
                    >
                      <b-icon
                        icon="delete"
                        class="is-clickable ml-1"
                        type="is-danger"
                        size="is-small"
                      ></b-icon>
                    </span>
                  </li>
                  <li
                    v-if="
                      show_thread_edit !== msg.id && msg.attachments.length > 1
                    "
                    class="mt-2"
                  >
                    <span @click="downloadAll(msg.attachments, msg.id)">
                      <b-icon
                        icon="tray-arrow-down"
                        class="is-clickable mr-1"
                        type="is-primary"
                      ></b-icon>
                    </span>
                    <a @click="downloadAll(msg.attachments, msg.id)">{{
                      $t("download_all")
                    }}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </b-tab-item>
          <b-tab-item
            v-if="
              ticket.products &&
              ticket.products.list &&
              ticket.products.list.length > 0
            "
            value="products"
          >
            <template #header>
              {{ ticket.products.label }}
              <b-tag
                :type="activeTab == 'products' ? 'is-primary' : 'is-light'"
                size="is-small"
                class="ml-2"
              >
                {{ ticket.products.count }}
              </b-tag>
            </template>
            <ValidationObserver
              ref="parentObserver"
              v-slot="{ handleSubmit }"
              tag="div"
            >
              <form
                ref="productsForm"
                autocomplete="off"
                @submit.prevent="handleSubmit(validateProducts)"
              >
                <ul v-if="ticket.products.list.length > 0" class="cart">
                  <li
                    v-for="(item, item_index) in ticket.products.list"
                    :key="item_index"
                    class="px-4"
                  >
                    <div class="columns is-mobile is-multiline">
                      <div class="column is-2-tablet is-4-mobile">
                        <div class="image-container">
                          <img
                            class="pt-2"
                            width="100"
                            height="100"
                            :src="
                              (item.img &&
                                `${item.img.url}${item.img.path}${item.img.filename}-w80h80@2x.${item.img.version}.jpg`) ||
                              '/img/blank.svg'
                            "
                            :alt="item.name"
                          />
                        </div>
                      </div>

                      <div class="det column is-5-tablet is-7-mobile px-0">
                        <div class="det">
                          <span class="is-block">
                            <b>{{ item.name }}</b>
                          </span>

                          <span class="has-text-grey is-size-8">
                            {{ item.details }}
                          </span>
                          <span class="has-text-grey is-size-8 is-block">
                            {{ $t("stock") + ": " + item.avs }}
                          </span>

                          <span class="is-block">
                            <b-tag
                              class="mt-1 mb-2"
                              type="is-danger is-light"
                              size="is-small"
                            >
                              {{ item.qty.claimed + " x " + item.subject.label }}
                            </b-tag>
                          </span>
                        </div>

                        <b-select-validation
                          v-if="item.status != 'refunded'"
                          v-model="item.qty.selected"
                          class="claim-select qty mt-1 ml-4"
                          :disabled="item.form.fields.qty.disabled"
                          :rules="
                            item.form.fields.qty.required ? 'required' : ''
                          "
                          hide-details
                          size="is-small"
                        >
                          <template>
                            <option
                              v-for="n in Number(item.qty.max)"
                              :key="n"
                              :value="n"
                            >
                              {{ n }}
                            </option>
                          </template>
                        </b-select-validation>
                        <div class="is-flex is-flex-wrap-wrap" v-else>
                          <b-select-validation
                            v-for="(p) in item.item_ids"
                            :key="p"
                            v-model="p.qty.selected"
                            class="claim-select qty mt-1 ml-4 mb-2"
                            :label="p.price.formatted"
                            :disabled="item.form.fields.qty.disabled"
                            :rules="
                              item.form.fields.qty.required ? 'required' : ''
                            "
                            hide-details
                            size="is-small"
                          >
                            <template>
                              <option :value="0">
                                0
                              </option>
                              <option
                                v-for="n in Number(p.qty.max)"
                                :key="n"
                                :value="n"
                              >
                                {{ n }}
                              </option>
                            </template>
                          </b-select-validation>
                        </div>
                      </div>
                      <div class="column is-5-tablet is-12-mobile">
                        <ValidationObserver :ref="`observer-${item_index}`">
                          <b-select-validation
                            class="mt-2"
                            v-model="item.status"
                            :label="item.form.fields.status.label"
                            :disabled="item.form.fields.status.disabled"
                            :rules="
                              item.form.fields.status.required ? 'required' : ''
                            "
                            hide-details
                            size="is-small"
                          >
                            <template>
                              <option
                                v-for="opt in item.statuses"
                                :key="opt.value"
                                :value="opt.value"
                              >
                                {{ opt.label }}
                              </option>
                            </template>
                          </b-select-validation>
                        </ValidationObserver> 

                        <b-input-validation
                          class="is-size-7 mt-4"
                          size="is-small"
                          v-model="item.notes"
                          rows="2"
                          has-counter
                          input-type="textarea"
                          maxlength="255"
                          :disabled="item.form.fields.notes.disabled"
                          :label="item.form.fields.notes.label"
                          :placeholder="placeholders.review.review"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </form>
            </ValidationObserver>
            <div class="is-flex is-justify-content-space-between mb-4">
              <b-button
                v-if="ticket.products.actions.validate"
                class="mt-4 ml-5"
                type="is-primary"
                :disabled="ticket.products.actions.validate.disabled"
                size="is-small"
                :value="$t('validate')"
                @click="submitValidateClaim"
              >
                {{ ticket.products.actions.validate.label }}
              </b-button>

              <b-button
                v-if="ticket.products.actions.return"
                class="mt-4 mx-5"
                type="is-primary"
                :loading="generateLabelLoading"
                :disabled="ticket.products.actions.return.disabled || productsLoading || generateLabelLoading"
                size="is-small"
                :value="ticket.products.actions.return.label"
                @click="showReturnLabel"
              >
                {{ ticket.products.actions.return.label }}
              </b-button>

              <!-- <ValidationObserver
                v-if="ticket.products.actions.return"
                ref="childObserver"
                v-slot="{ handleSubmit }"
                tag="div"
              >
                <form
                  autocomplete="off"
                  @submit.prevent="handleSubmit(generateReturnLabel)"
                >
                  <b-field addons class="mt-4 mx-5">
                    <p class="control is-expanded">
                      <b-select-validation
                        v-model="carrier"
                        :label="ticket.products.forms.return.carrier.label"
                        :disabled="
                          ticket.products.forms.return.carrier.disabled
                        "
                        size="is-small"
                        rules="required"
                        style="width: 140px"
                      >
                        <template>
                          <option
                            v-for="opt in carriers"
                            :key="opt.id"
                            :value="opt"
                          >
                            {{ opt.label }}
                          </option>
                        </template>
                      </b-select-validation>
                    </p>
                    <p class="control is-expanded">
                      <b-input-validation
                        v-if="
                          (carrier && !carrier.api) ||
                          ticket.products.forms.return.tracking.disabled
                        "
                        v-model="tracking_number"
                        :disabled="
                          ticket.products.forms.return.tracking.disabled
                        "
                        :rules="carrier && carrier.api ? '' : 'required'"
                        :label="ticket.products.forms.return.tracking.label"
                        :placeholder="placeholders.order.tracking_num"
                        size="is-small"
                      />
                    </p>
                    <p class="control">
                      <b-button
                        v-if="ticket.products.actions.return"
                        type="is-primary"
                        :loading="productsLoading"
                        native-type="submit"
                        size="is-small"
                      >
                        <b-icon icon="check" size="is-small" />
                      </b-button>
                    </p>
                  </b-field>
                </form>
              </ValidationObserver> -->

              <b-button
                v-if="ticket.products.actions.order"
                class="mt-4 mr-5"
                type="is-primary"
                outlined
                size="is-small"
                :loading="generateOrderLoading"
                :disabled="ticket.products.actions.order.disabled"
                :value="ticket.products.actions.order.label"
                @click="generateOrder()"              
              >
                {{ ticket.products.actions.order.label }}
              </b-button>
            </div>
          </b-tab-item>
          <b-tab-item value="attachments">
            <template #header>
              {{ attachments.label }}
              <b-tag
                :type="activeTab == 'attachments' ? 'is-primary' : 'is-light'"
                size="is-small"
                class="ml-2"
              >
                {{ attachments.list.length }}
              </b-tag>
            </template>
            <b-table
              ref="table"
              :data="attachments.list"
              :loading="ticket_loading"
              hoverable
              sortable
            >
              <template v-for="h in attachmentsHeaders">
                <b-table-column
                  :key="h.value"
                  v-slot="props"
                  :field="h.value"
                  :label="h.label"
                  :width="h.width"
                  :sortable="h.sortable"
                >
                  <template v-if="h.value == 'label'">
                    <b-icon
                      :icon="
                        props.row.type === 'image'
                          ? 'file-image-outline'
                          : props.row.type === 'pdf'
                          ? 'file-pdf-box'
                          : 'file-document-outline'
                      "
                      class="baseline mr-1"
                      type="is-primary"
                    ></b-icon>
                    <a
                      v-if="props.row.type === 'image'"
                      @click="openSlides('views', null, props.index - 1)"
                    >
                      {{
                        props.row.label ? props.row.label : "-" | truncate(20)
                      }}</a
                    >
                    <a
                      v-else-if="
                        props.row.type === 'pdf' || props.row.type === 'doc'
                      "
                      :href="props.row.path"
                      target="_blank"
                    >
                      {{
                        props.row.label ? props.row.label : "-" | truncate(20)
                      }}</a
                    >

                    <b-tag
                      v-if="props.row.internal"
                      type="is-warning"
                      size="is-small"
                      class="ml-2"
                    >
                      {{ $t("note") }}
                    </b-tag>
                  </template>
                  <template v-if="h.value == 'by'">
                    {{
                      props.row.agent
                        ? props.row.agent.fname + " " + props.row.agent.lname
                        : ticket.user.fname + " " + ticket.user.lname
                    }}
                  </template>
                  <template v-else-if="h.value == 'size'">
                    {{ props.row.size ? props.row.size : "-" }}
                  </template>
                  <template v-else-if="h.value == 'created_at.datetime'">
                    <b-tooltip position="is-bottom" type="is-primary is-light">
                      <span>
                        {{
                          (props.row.created_at && props.row.created_at.ago) ||
                          "-"
                        }}
                      </span>
                      <template v-slot:content>
                        <span>
                          {{
                            (props.row.created_at &&
                              props.row.created_at.formatted) ||
                            "-"
                          }}
                          <span class="has-text-grey">{{
                            props.row.created_at && props.row.created_at.time
                          }}</span>
                        </span>
                      </template>
                    </b-tooltip>
                  </template>
                </b-table-column>
              </template>

              <EmptyBlock slot="empty" icon="timeline-text" />
            </b-table>
          </b-tab-item>
          <b-tab-item value="customer_history">
            <template #header>
              {{ tickets.label }}
              <b-tag
                :type="
                  activeTab == 'customer_history' ? 'is-primary' : 'is-light'
                "
                size="is-small"
                class="ml-2"
              >
                {{ tickets.count }}
              </b-tag>
            </template>
            <b-table
              :data="tickets.list"
              :loading="ticket_loading"
              paginated
              :per-page="perPage"
              :current-page="currentPageTickets"
              hoverable
              mobile-cards
              striped
              :row-class="
                (row, index) => (row.seen ? '' : 'has-text-weight-bold')
              "
            >
              <template v-for="head in headers">
                <b-table-column
                  :key="head.value + '_' + head.display"
                  :visible="head.display"
                  :field="head.field"
                  :label="head.label"
                  :width="head.width"
                  :centered="head.centered"
                  :sortable="head.sortable"
                  :searchable="false"
                  v-bind="head"
                >
                  <!-- site -->
                  <template v-if="head.value == 'site_id'" v-slot="props">
                    <img
                      height="20"
                      width="20"
                      :src="'/img/sites/' + props.row.site.id + '.svg'"
                    />
                  </template>

                  <!-- Ticket -->
                  <template
                    v-else-if="head.value === 'ticket_id'"
                    v-slot="props"
                  >
                    <!-- <router-link
						:to="`/cs/tickets/${props.row.ticket_id}`"
						target="_blank"
					  >
						{{ props.row.id }}
					  </router-link> -->
                    <a href="javascript:;" @click="getTicket(props.row.id)">{{
                      props.row.id
                    }}</a>
                    <router-link
                      :to="`/cs/tickets/${props.row.id}`"
                      target="_blank"
                    >
                      <b-icon icon="open-in-new" size="is-small" class="ml-1">
                      </b-icon>
                    </router-link>
                  </template>

                  <!-- User -->
                  <template v-else-if="head.value === 'user'" v-slot="props">
                    <router-link :to="`/users/${props.row.user.id}`">{{
                      props.row.user &&
                      props.row.user.fname + " " + props.row.user.lname
                    }}</router-link>
                  </template>

                  <!-- Email -->
                  <template v-else-if="head.value === 'email'" v-slot="props">
                    {{ props.row.user && props.row.user.email }}
                  </template>

                  <!-- subject -->
                  <template v-else-if="head.value === 'subject'" v-slot="props">
                    {{ props.row.subject.label }}
                  </template>

                  <!-- created_at -->
                  <template
                    v-else-if="head.value === 'created_at.formatted'"
                    v-slot="props"
                  >
                    <b-tooltip position="is-bottom" type="is-primary is-light">
                      <span>
                        {{
                          (props.row.created_at && props.row.created_at.ago) ||
                          "-"
                        }}
                      </span>
                      <template v-slot:content>
                        <span>
                          {{
                            (props.row.created_at &&
                              props.row.created_at.formatted) ||
                            "-"
                          }}
                          <span class="has-text-grey">{{
                            props.row.created_at && props.row.created_at.time
                          }}</span>
                        </span>
                      </template>
                    </b-tooltip>
                  </template>

                  <!-- status -->
                  <template
                    v-else-if="head.value === 'status.label'"
                    v-slot="props"
                  >
                    <b-tag :type="getColor(props.row.status.color)">
                      {{ (props.row.status && props.row.status.label) || "-" }}
                    </b-tag>
                  </template>

                  <!-- agent -->
                  <template v-else-if="head.value == 'agent_id'" v-slot="props">
                    {{
                      props.row.agent
                        ? props.row.agent.fname + " " + props.row.agent.lname
                        : "-"
                    }}
                  </template>

                  <!-- Order -->
                  <template
                    v-else-if="head.value === 'order_id'"
                    v-slot="props"
                  >
                    <a
                      href="javascript:;"
                      @click="
                        openDrawer('orderForm', {
                          key: props.row.order_id,
                          index: props.index,
                          order_id: props.row.order_id,
                          user_id: props.row.user.id,
                          site_id: props.row.site.id,
                          warehouse_id: warehouse_id,
                          mp_id: props.row.mp_id,
                          warehouses: warehouses,
                        })
                      "
                      >{{ props.row.order_id }}</a
                    >
                  </template>

                  <template v-else v-slot="props">
                    <b-icon
                      v-if="typeof props.row[head.value] === 'boolean'"
                      custom-size="mdi-18px"
                      :icon="props.row[head.value] ? 'check' : 'close'"
                      :type="props.row[head.value] ? 'is-success' : 'is-danger'"
                    />
                    <template v-else>
                      {{ props.row[head.value] || "-" }}
                    </template>
                  </template>
                </b-table-column>
              </template>
              <EmptyBlock slot="empty" icon="cart" />
            </b-table>
            <Pagination
              :per-page="perPage"
              no-per-pages
              :total="tickets.count"
              :current-page="currentPageTickets"
              @update-per-page="(val) => (perPage = val)"
              @update-current-page="(val) => (currentPageTickets = val)"
            />
          </b-tab-item>
          <b-tab-item value="activities">
            <template #header>
              {{ activities.label }}
              <b-tag
                :type="activeTab == 'activities' ? 'is-primary' : 'is-light'"
                size="is-small"
                class="ml-2"
              >
                {{ activities.count }}
              </b-tag>
            </template>
            <b-table
              ref="table"
              :data="activities.list"
              :loading="ticket_loading"
              hoverable
              sortable
              paginated
              :per-page="perPage"
              :current-page="currentPageActivities"
            >
              <template v-for="h in activitiesHeaders">
                <b-table-column
                  :key="h.value"
                  :field="h.field"
                  :label="h.label"
                  :width="h.width"
                  :searchable="h.searchable"
                  :sortable="h.sortable"
                  v-bind="h"
                >
                  <!-- v-if="head.searchable"    
					 v-slot="props" -->
                  <template v-if="h.value == 'agent.name'" #searchable="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      :placeholder="placeholders.user.fname"
                      rounded
                      size="is-small"
                    />
                  </template>
                  <template v-else #searchable="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      :placeholder="placeholders.number['3']"
                      rounded
                      size="is-small"
                    />
                  </template>

                  <template v-if="h.value == 'agent.name'" v-slot="props">
                    {{ props.row.agent ? props.row.agent.signature : "-" }}
                  </template>
                  <template v-else-if="h.value == 'label'" v-slot="props">
                    {{ props.row.label }}
                  </template>
                  <template v-else-if="h.value == 'created_at'" v-slot="props">
                    <b-tooltip position="is-bottom" type="is-primary is-light">
                      <span>
                        {{
                          (props.row.created_at && props.row.created_at.ago) ||
                          "-"
                        }}
                      </span>
                      <template v-slot:content>
                        <span>
                          {{
                            (props.row.created_at &&
                              props.row.created_at.formatted) ||
                            "-"
                          }}
                          <span class="has-text-grey">{{
                            props.row.created_at && props.row.created_at.time
                          }}</span>
                        </span>
                      </template>
                    </b-tooltip>
                  </template>
                  <template v-else-if="h.value == 'id'" v-slot="props">
                    <span v-if="props.row.id" v-html="props.row.id" />
                    <span v-else>-</span>
                  </template>
                  <template v-else-if="h.value == 'details'" v-slot="props">
                    <span v-if="props.row.details" v-html="props.row.details" />
                    <span v-else>-</span>
                  </template>
                  <template v-else-if="h.value == 'ip'" v-slot="props">
                    <template>
                      {{ props.row.ip }}
                    </template>
                    <b-tooltip
                      :label="props.row.user_agent"
                      position="is-bottom"
                      multilined
                    >
                      <b-icon
                        icon="information-variant-circle-outline"
                        type="is-info"
                        size="is-small"
                      />
                    </b-tooltip>
                  </template>
                  <template v-else-if="h.value == 'tld'" v-slot="props">
                    {{ (props.row.tld && props.row.tld.label) || "-" }}
                  </template>
                </b-table-column>
              </template>

              <EmptyBlock slot="empty" icon="timeline-text" />
            </b-table>
            <Pagination
              :per-page="perPage"
              no-per-pages
              :total="activities.count"
              :current-page="currentPageActivities"
              @update-per-page="(val) => (perPage = val)"
              @update-current-page="(val) => (currentPageActivities = val)"
            />
          </b-tab-item>
        </b-tabs>
        <div v-if="forms.reply" class="footer">
          <transition name="slide-fade" mode="out-in">
            <b-input-validation
              v-if="!show_thread_field"
              id="reply-field"
              v-model="thread"
              hide-details
              :placeholder="forms.reply.fields.thread.placeholder"
              @focus="showThread()"
            />
            <div v-if="show_thread_field" id="reply-field">
              <ValidationObserver
                ref="observer"
                v-slot="{ handleSubmit }"
                tag="div"
              >
                <form
                  method="post"
                  autocomplete="off"
                  @submit.prevent="handleSubmit(checkCollision)"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    tag="div"
                    name="editor"
                    rules="required"
                    mode="passive"
                  >
                    <b-field
                      id="msg"
                      ref="thread"
                      class="editor inline mb-0"
                      :class="{
                        danger: errors[0],
                      }"
                      :disabled="disabled"
                      :type="{
                        'is-default': disabled,
                        'is-danger': errors[0],
                      }"
                    >
                      <froala
                        ref="editor"
                        id="editor"
                        v-model="thread"
                        class="ticket-editor"
                        :config="editorConfig"
                        hide-details
                      />
                    </b-field>
                  </ValidationProvider>

                  <ImageUpload
                    v-if="renderUploader"
                    class="ticket-uploader small-uploader mt-0"
                    :label="forms.reply.fields.attachment.placeholder"
                    :allow-multiple="true"
                    :images="images"
                    :required="false"
                    :accepted-file-types="
                      forms.reply.fields.attachment.accepted.extensions
                    "
                    imagePreviewHeight="39px"
                    @updateFiles="(files) => (images = files)"
                  />
                  <div class="is-flex">
                    <b-field class="mt-3 mr-2">
                      <p class="control">
                        <b-button
                          type="is-primary"
                          :loading="sendLoading"
                          :disabled="sendLoading"
                          native-type="submit"
                        >
                          {{
                            internal
                              ? forms.reply.actions.internal_note.alternate
                              : forms.reply.actions.send.label
                          }}
                        </b-button>
                      </p>
                      <p class="control">
                        <b-dropdown position="is-top-right">
                          <template #trigger>
                            <b-button
                              :disabled="sendLoading || internal"
                              type="is-primary"
                              icon-left="chevron-down"
                            />
                          </template>

                          <b-dropdown-item
                            :disabled="sendLoading || internal"
                            @click="checkCollision(true)"
                          >
                            {{ forms.reply.actions.send_close.label }}
                          </b-dropdown-item>
                          <!-- <b-dropdown-item
						  :disabled="sendLoading || internal"
						  @click="openScheduleModal()"
						>
						  {{ $t("schedule_sending") }}
						</b-dropdown-item> -->
                        </b-dropdown>
                      </p>
                    </b-field>
                    <b-switch
                      class="ml-4"
                      v-model="internal"
                      :disabled="sendLoading"
                    >
                      {{ forms.reply.actions.internal_note.label }}</b-switch
                    >
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </transition>
        </div>
      </div>
      <div
        id="form"
        class="scrollable column is-3-tablet is-12-mobile pt-2"
        :class="{ 'px-0': $device.mobile }"
      >
        <div class="sticky sidebar">
          <div class="close" @click="$emit('close')">
            <b-icon icon="close" />
          </div>
          <p class="is-size-5 pb-2">
            <b>{{ $t("details") }}</b>
          </p>
          <div>
            <img
              height="17"
              width="17"
              :src="'/img/sites/' + ticket.site.id + '.svg'"
              class="mr-2"
            /><span>{{ ticket.site.site + " - " + ticket.site.tld }}</span>
          </div>
          <div>
            <b-icon icon="account" size="is-small" class="mr-2"> </b-icon>
            {{ ticket.user.fname + " " + ticket.user.lname }}
            <router-link :to="`/users/${ticket.user.id}`" target="_blank">
              <b-icon icon="open-in-new" size="is-small" class="ml-1"> </b-icon>
            </router-link>
          </div>

          <!-- <div>
            <b-icon icon="web" size="is-small" class="mr-2"> </b-icon>
            <span>
              {{ ticket.origin }}
            </span>
          </div> -->
          <div v-if="ticket.order.id">
            <b-icon icon="cart" size="is-small" class="mr-2"> </b-icon>
            <a
              href="javascript:;"
              @click="
                openDrawer('orderForm', {
                  key: ticket.order.id,
                  order_id: ticket.order.id,
                  user_id: ticket.user.id,
                  site_id: ticket.site.id,
                  warehouse_id: 1,
                  warehouses: warehouses,
                })
              "
              >{{ ticket.order.id }}</a
            >
          </div>
          <div v-if="ticket.new_order_id" class="ml-5">
            <b-icon icon="arrow-u-right-bottom" size="is-small" class="mr-2">
            </b-icon>
            <a
              href="javascript:;"
              @click="
                openDrawer('orderForm', {
                  key: ticket.new_order_id,
                  order_id: ticket.new_order_id,
                  user_id: ticket.user.id,
                  site_id: ticket.site.id,
                  warehouse_id: 1,
                  warehouses: warehouses,
                })
              "
              >{{ ticket.new_order_id }}</a
            >
          </div>
          <div v-if="ticket.products.list.length > 0">
            <b-icon icon="package-variant" size="is-small" class="mr-2">
            </b-icon>
            <span class="is-size-7">
              <b>{{ ticket.products.label }}</b>
            </span>
          </div>
          <ul v-if="ticket.products.list.length > 0" class="ml-2">
            <template v-for="prod in ticket.products.list">
              <li class="is-size-7 ml-4" :key="prod.id">
                {{ prod.name }}
                <router-link
                  :to="`/catalog/products/${prod.id}`"
                  target="_blank"
                >
                  <b-icon icon="open-in-new" size="is-small" class="ml-1">
                  </b-icon>
                </router-link>
              </li>
            </template>
          </ul>
          <div>
            <b-icon icon="calendar" size="is-small" class="mr-2"> </b-icon>
            <b-tooltip position="is-top" type="is-primary is-light">
              <span>
                {{ ticket.created_at.ago }}
              </span>
              <template v-slot:content>
                <!-- {{ $t("created_at") }}: -->
                <span>
                  {{
                    (ticket.created_at && ticket.created_at.formatted) || "-"
                  }}
                  <span class="has-text-grey">{{
                    ticket.created_at && ticket.created_at.time
                  }}</span>
                </span>
                <!-- <br />
                <span>
                  {{ $t("updated_at") }}:
                  {{
                    (ticket.last_thread_at &&
                      ticket.last_thread_at.formatted) ||
                    "-"
                  }}
                  <span class="has-text-grey">{{
                    ticket.last_thread_at && ticket.last_thread_at.time
                  }}</span>
                </span> -->
              </template>
            </b-tooltip>
          </div>
          <!-- 
          <div>
            <b-icon icon="calendar" size="is-small" class="mr-2"> </b-icon>
            <span>{{ ticket.created_at.formatted }}</span>
          </div> -->

          <p class="is-size-5 pb-2 pt-4">
            <b>{{ $t("options") }}</b>
          </p>

          <b-field
            :label="forms.options.fields.status.label"
            custom-class="is-small mb-0"
            label-position="default"
          >
            <b-select-validation
              v-model="status"
              hide-details
              :disabled="forms.options.fields.status.disabled"
              size="is-small"
            >
              <template>
                <option v-for="opt in statuses" :key="opt.id" :value="opt.id">
                  {{ opt.label }}
                </option>
              </template>
            </b-select-validation>
          </b-field>

          <b-field
            :label="forms.options.fields.subject.label"
            custom-class="is-small mb-0"
            label-position="default"
          >
            <b-select-validation
              v-model="subject"
              hide-details
              :disabled="forms.options.fields.subject.disabled"
              size="is-small"
            >
              <template>
                <option v-for="opt in subjects" :key="opt.id" :value="opt.id">
                  {{ opt.label }}
                </option>
              </template>
            </b-select-validation>
          </b-field>
          <b-field
            :label="forms.options.fields.assignee.label"
            custom-class="is-small mb-0"
            label-position="default"
          >
            <b-select-validation
              v-model="assigned_to"
              hide-details
              :disabled="forms.options.fields.assignee.disabled"
              size="is-small"
            >
              <template>
                <option v-for="opt in agents" :key="opt.id" :value="opt.id">
                  {{ opt.name }}
                </option>
              </template>
            </b-select-validation>
          </b-field>
          <b-field
            :label="forms.options.fields.priority.label"
            custom-class="is-small mb-0"
            label-position="default"
          >
            <b-select-validation
              v-model="priority.key"
              hide-details
              :disabled="forms.options.fields.priority.disabled"
              size="is-small"
            >
              <template>
                <option
                  v-for="opt in priorities"
                  :key="opt.key"
                  :value="opt.key"
                >
                  {{ opt.label }}
                </option>
              </template>
            </b-select-validation>
          </b-field>

          <b-field
            :label="forms.options.fields.tags.label"
            custom-class="is-small mb-0"
            label-position="default"
            v-if="!showAddTag"
            addons
          >
            <b-taginput
              ref="tags"
              v-model="selectedTags"
              open-on-focus
              :data="filteredTags"
              hide-details
              :disabled="forms.options.fields.tags.disabled"
              autocomplete
              field="label"
              size="is-small"
              expanded
              ellipsis
              :placeholder="selectedTags.length == 0 ? $t('choose') : ''"
              @focus="getFilteredTags"
              @add="getFilteredTags"
              @remove="getFilteredTags"
              @typing="getFilteredTags"
            >
              <template slot-scope="props">
                <b-tag
                  :tabstop="false"
                  ellipsis
                  :style="{
                    background: props.option.background,
                    color: props.option.color,
                    border: '1px solid' + props.option.border,
                  }"
                >
                  {{ props.option.tag }}
                </b-tag>
              </template>
              <template slot="selected" slot-scope="props">
                <b-tag
                  v-for="(tag, index) in props.tags"
                  :key="tag.id"
                  :tabstop="false"
                  ellipsis
                  closable
                  class="is-clickable"
                  :style="{
                    background: tag.background,
                    color: tag.color,
                    border: '1px solid' + tag.border,
                  }"
                  @close.prevent="$refs.tags.removeTag(index, $event)"
                  @click="showAddTag = true"
                >
                  {{ tag.tag }}
                </b-tag>
              </template>
              <template slot="empty">
                {{ $t("no_result_found") }}
              </template>
            </b-taginput>
            <p
              v-show="
                filteredTags.length == 0 &&
                forms.options.actions.add_tag &&
                forms.options.actions.add_tag.form
              "
              class="control"
            >
              <b-button
                size="is-small"
                class="button"
                @click="showAddTag = true"
              >
                <b-icon icon="plus" type="is-grey-light" size="is-small" />
              </b-button>
            </p>
          </b-field>

          <transition name="slide-fade" mode="out-in">
            <div v-if="showAddTag" class="new-tag bg-cream mb-4">
              <b-input-validation
                class="mt-2"
                size="is-small"
                v-model="newTag.tag"
                vid="newTag"
                :placeholder="placeholders.carrier.name"
                :label="forms.options.actions.add_tag.form.fields.tag.label"
              />
              <ul class="mt-1 mb-4 color-palette small">
                <li
                  v-for="col in colors"
                  :key="col.background + col.border"
                  :class="
                    col.background === newTag.background &&
                    col.color === newTag.color
                      ? 'selected'
                      : ''
                  "
                  :style="{
                    background: col.background,
                    border: '1px solid' + col.border,
                  }"
                  @click="selectColor(col)"
                ></li>
              </ul>
              <div v-if="newTag.tag" class="mb-2">
                <span class="is-size-7 mr-2">{{
                  forms.options.actions.add_tag.form.texts.preview.label + ":"
                }}</span>
                <b-tag
                  :style="{
                    background: newTag.background,
                    color: newTag.color,
                    border: '1px solid' + newTag.border,
                  }"
                  >{{ newTag.tag }}</b-tag
                >
              </div>
              <b-button
                type="is-primary"
                size="is-small"
                class="mr-3"
                :disabled="new_tag_loading"
                :loading="new_tag_loading"
                tag="input"
                native-type="submit"
                @click="addNewTag()"
                :value="forms.options.actions.add_tag.form.actions.add.label"
              >
                {{ forms.options.actions.add_tag.form.actions.add.label }}
              </b-button>
              <b-button
                :disabled="new_tag_loading"
                size="is-small"
                @click="showAddTag = false"
              >
                {{ forms.options.actions.add_tag.form.actions.cancel.label }}
              </b-button>
            </div>
          </transition>
          <b-field
            :label="forms.options.fields.followers.label"
            addons
            custom-class="is-small mb-0"
            label-position="default"
          >
            <b-taginput
              ref="followers"
              v-model="followers"
              class="mb-3"
              open-on-focus
              :data="filteredAgents"
              hide-details
              :disabled="forms.options.fields.followers.disabled"
              autocomplete
              field="label"
              size="is-small"
              expanded
              ellipsis
              :placeholder="followers.length == 0 ? $t('choose') : ''"
              @add="getFilteredAgents"
              @remove="getFilteredAgents"
              @typing="getFilteredAgents"
              @focus="getFilteredAgents"
            >
              <template slot-scope="props">
                <span v-html="props.option.name" />
              </template>
              <template slot="selected" slot-scope="props">
                <b-tag
                  v-for="(tag, index) in props.tags"
                  :key="tag.agent_id"
                  :tabstop="false"
                  ellipsis
                  closable
                  class="is-clickable"
                  @close.prevent="$refs.followers.removeTag(index, $event)"
                >
                  {{ tag.signature }}
                </b-tag>
              </template>
              <template slot="empty">
                {{ $t("no_result_found") }}
              </template>
            </b-taginput>
          </b-field>
          <b-button
            class="mt-4"
            type="is-primary"
            :disabled="forms.options.actions.update.disabled"
            :loading="options_loading"
            @click="updateOptions()"
            :value="$t('update')"
          >
            {{ forms.options.actions.update.label }}
          </b-button>
        </div>
      </div>
    </div>
    <b-modal v-model="showDeleteAttachment" :width="400">
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ $t("are_you_sure") }}</b>
          </div>
          <hr class="my-0" />
        </div>
        <div class="columns is-multiline is-mobile">
          <div class="column is-12 mt-0">
            <b-button class="mr-4" type="is-danger" @click="deleteAttachment">
              {{ $t("confirm") }}
            </b-button>
            <b-button @click="cancelDelete">
              {{ $t("cancel") }}
            </b-button>
          </div>
        </div>
      </Card>
    </b-modal>
    <b-modal v-model="showProductConfirmation" :width="400">
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ $t("are_you_sure") }}</b>
          </div>
          <hr class="my-0" />
        </div>
        <div class="columns is-multiline is-mobile">
          <div class="column is-12 mt-0">
            <b-button 
              class="mr-4" 
              type="is-primary" 
              :loading="productsLoading"
              :disabled="productsLoading"
              @click="confirmClaim()"
            >
              {{ $t("confirm") }}
            </b-button>
            <b-button 
              :disabled="productsLoading" 
              @click="cancelClaim()"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </div>
      </Card>
    </b-modal>
    <b-modal v-model="showCollisionModal" :width="500">
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ collision && collision.title }}</b>
          </div>
          <hr class="my-0" />
        </div>
        <div class="mb-4">
          {{ collision && collision.message }}
        </div>

        <b-button
          class="mr-4"
          type="is-primary"
          @click="
            sendThread(sendAndClose);
            showCollisionModal = false;
          "
        >
          {{ collision && collision.actions.send_anyway.label }}
        </b-button>
        <b-button
          class="mr-4"
          @click="
            internal = true;
            sendThread(sendAndClose);
            showCollisionModal = false;
          "
        >
          {{ collision && collision.actions.add_note.label }}
        </b-button>
        <b-button
          type="is-danger"
          outlined
          @click="
            cancel();
            showCollisionModal = false;
          "
        >
          {{ collision && collision.actions.cancel.label }}
        </b-button>
      </Card>
    </b-modal>
    <b-modal v-model="showShippedModal" :width="800" :height="520">
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ ticket.products.actions.return.label }}</b>
          </div>
          <hr class="my-0" />
        </div>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }" tag="div">
          <form
            class="columns is-multiline is-mobile"
            autocomplete="off"
            @submit.prevent="handleSubmit(generateReturnLabel)"
          >
            <div
              v-for="(num, i) in editedTrackingNums"
              class="column is-6"
              :key="'return' + i"
            >
              <b-field addons>
                <p class="control">
                  <b-select-validation
                    v-model="editedTrackingNums[i].carrier"
                    :label="$tc('carrier', 1)"
                    vid="carrier"
                    hide-details
                    @input="setTracking()"
                  >
                    <template>
                      <option
                        v-for="opt in carriers"
                        :key="opt.id"
                        :value="opt.id"
                      >
                        {{ opt.label }}
                      </option>
                    </template>
                  </b-select-validation>
                </p>
                <p class="control">
                  <b-input-validation
                    v-model="editedTrackingNums[i].tracking_number"
                    :label="$t('tracking_number')"
                    :placeholder="
                      !carrierHasApi(editedTrackingNums[i].carrier)
                        ? placeholders.order.tracking_num
                        : placeholders.carrier.automatic
                    "
                    :disabled="carrierHasApi(editedTrackingNums[i].carrier)"
                    hide-details
                    @input="setTracking()"
                  />
                </p>
                <!-- <p class="control">
                    <b-button type="is-primary" @click.stop="showCalendar(i)">
                      <b-icon icon="calendar" size="is-small" />
                    </b-button>
                  </p> -->
              </b-field>
            </div>

            <div class="column is-12">
              <b-button
                class="mr-4"
                type="is-success"
                tag="input"
                :value="$t('validate')"
                :loading="generateLabelLoading"
                :disabled="generateLabelLoading"
                native-type="submit"
              >
                {{ $t("validate") }}
              </b-button>
              <b-button @click="showShippedModal = false">
                {{ $t("cancel") }}
              </b-button>
            </div>
          </form>
        </ValidationObserver>
      </Card>
    </b-modal>
    <!-- <b-modal v-model="scheduleModal" :width="380">
		<Card>
		  <div slot="header">
			<div class="is-size-5 px py-4">
			  <b>{{ $t("schecule_sending") }}</b>
			</div>
			<hr class="my-0" />
		  </div>
		  <div class="columns is-multiline is-mobile">
			<b-switch v-model="scheduleClose" class="column is-12">
			  {{ $t("close_ticket") }}
			</b-switch>
			<b-datepicker-validation
			  class="column is-12"
			  v-model="scheduleDate"
			  :placeholder="placeholders.date.time"
			  format="YYYY-MM-DD HH:mm:ss"
			  time-picker
			  inline
			/>
  
			<div class="column is-12">
			  <b-button
				class="mr-4"
				type="is-primary"
				tag="input"
				:value="$t('validate')"
				@click="scheduleSend()"
			  />
  
			  <b-button @click="scheduleModal = false">
				{{ $t("cancel") }}
			  </b-button>
			</div>
		  </div>
		</Card>
	  </b-modal> -->
    <div v-show="slides" class="zoom-in">
      <div class="wrap" @click="slides = false">
        <b-icon
          icon="close"
          size="is-medium"
          class="close pb-4"
          @click="slides = false"
        />
        <component
          :is="slides_content"
          :key="current_view"
          :slides="ticket_slides"
          :name="ticket.subject.label"
          :window="window"
        />
      </div>
    </div>
  </div>
  <b-loading v-else v-model="ticket_loading" :is-full-page="false" />
</template>
	  
	<script>
import ImageUpload from "@/components/forms/elements/ImageUpload";
import { ValidationProvider } from "vee-validate";

export default {
  components: { ImageUpload, ValidationProvider },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      //loadings
      ticket_loading: false,
      sendLoading: false,
      options_loading: false,
      edit_loading: false,
      productsLoading: false,
      generateOrderLoading: false,
      new_tag_loading: false,
      generateLabelLoading: false,
      renderUploader: true,

      cancelled: false,
      timeoutId: null,
      sendAndClose: false,
      disabled: false,

      show_thread_field: false,
      showDeleteAttachment: false,
      showCollisionModal: false,
      showShippedModal: false,
      showProductConfirmation: false,
      show_thread_edit: null,

      editedTrackingNums: [],
      trackingNums: [],

      ticket_id: null,
      ticket: {},
      tickets: {},
      forms: {},
      activities: {},
      thread: "",
      editedThread: "",
      threads: {},
      threadUpdated: -1,
      attachments: {},
      images: [],
      current_view: "",
      slides: false,
      slides_content: null,
      window: 0,
      ticket_slides: [],
      carrier: null,

      collision: null,
      deleteAtt: null,
      deleteAttMsg: null,

      //schedule send
      // scheduleModal: false,
      // scheduleDate: new Date(),

      internal: false,
      filteredAgents: [],
      filteredTags: [],
      tags: [],

      // options
      status: null,
      subject: null,
      assigned_to: null,
      priority: null,

      // pagination
      perPage: 10,
      currentPageTickets: 1,
      currentPageActivities: 1,

      showAddTag: false,
      selectedColor: "",
      newTag: { background: "", color: "", border: "", tag: "" },

      activeTab: "threads",

      //filters
      subjects: [],
      agents: this.options.agents,
      priorities: [],
      statuses: [],
      selectedTags: [],
      followers: [],
      colors: [],
      carriers: [],

      editorConfig: {
        key: process.env.VUE_APP_FROALA_KEY,
        toolbarInline: true,
        entities: "",
        pastePlain: true,
        htmlSimpleAmpersand: true,
        // enter: FroalaEditor.ENTER_BR,
        tabSpaces: 4,
        multiLine: true,
        charCounterMax: 512,
        shortcutsEnabled: ["bold", "italic", "underline"],
        pluginsEnabled: ["entities", "lineBreaker"],
        toolbarButtons: {
          moreText: {
            buttons: ["bold", "italic", "underline"],
            buttonsVisible: 3,
          },
        },

        events: {
          "html.get": function (html) {
            return html.replace(/id="isPasted"/g, "");
          },
        },
      },

      //headers
      activitiesHeaders: [
        {
          value: "id",
          field: "id",
          label: this.$t("id"),
          width: "100",
          searchable: true,
          sortable: true,
        },
        {
          value: "label",
          field: "label",
          label: this.$tc("activity", 1),
          width: "250",
          searchable: true,
          sortable: true,
        },
        {
          value: "agent.name",
          field: "agent.name",
          label: this.$tc("agent", 1),
          width: "160",
          searchable: true,
          sortable: true,
        },
        {
          value: "details",
          label: this.$t("details"),
          searchable: true,
          width: "250",
        },
        {
          value: "created_at",
          field: "created_at.datetime",
          label: this.$t("date"),
          width: "180",
          searchable: false,
          sortable: true,
        },
      ],
      attachmentsHeaders: [
        {
          value: "label",
          label: this.$t("file_name"),
          width: "250",
          sortable: true,
        },
        {
          value: "by",
          label: this.$t("by"),
          width: "160",
          sortable: true,
        },
        {
          value: "size",
          label: this.$t("size"),
          width: "100",
          sortable: true,
        },
        {
          value: "created_at.datetime",
          label: this.$t("date"),
          width: "180",
          sortable: true,
        },
      ],
      headers: [
        {
          label: this.$t("site"),
          value: "site_id",
          field: "site.id",
          sortable: true,
          searchable: true,
          width: "80",
          filter_type: "select",
          filter_text: "label",
          filter_id: "id",
          filter_values: [],
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$t("id"),
          value: "ticket_id",
          field: "id",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "numeric",
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$t("email"),
          value: "email",
          sortable: true,
          searchable: true,
          width: "180",
          input: "",
          filter: "contains",
          display: false,
        },
        {
          label: this.$tc("subject", 1),
          value: "subject",
          field: "subject.id",
          width: "300",
          sortable: true,
          searchable: true,
          input: "",
          filter: "contains",
          display: true,
        },
        {
          label: this.$tc("agent", 1),
          value: "agent_id",
          field: "agent.fname",
          sortable: true,
          searchable: true,
          width: "180",
          filter_type: "select",
          filter_text: "signature",
          filter_id: "id",
          filter_values: [],
          input: "",
          display: true,
        },
        {
          label: this.$i18n.t("created_at"),
          value: "created_at.formatted",
          field: "created_at.datetime",
          sortable: true,
          searchable: true,
          width: "160",
          filter_type: "date",
          input: null,
          filter: "is",
          display: true,
        },
        {
          label: this.$i18n.t("solved_at"),
          value: "solved_at",
          sortable: true,
          searchable: true,
          width: "160",
          filter_type: "date",
          input: null,
          filter: "is",
          display: false,
        },
        {
          label: this.$t("status"),
          value: "status.label",
          field: "status.label",
          sortable: true,
          searchable: true,
          width: "180",
          filter_type: "select",
          filter_text: "label",
          filter_id: "id",
          filter_values: [],
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$tc("order", 1),
          value: "order_id",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "numeric",
          input: "",
          filter: "is",
          display: false,
        },
      ],
    };
  },

  mounted() {
    this.$bus.$on("update-ticket", (params) => {
      this.ticket_id = params.ticket_id;
      this.getTicket(this.ticket_id);
    });
  },

  created() {
    this.ticket_id = this.options.ticket_id;
    this.getTicket(this.ticket_id);
  },

  destroyed() {
    this.$bus.$off("update-ticket");
  },

  methods: {
    getTicket(id) {
      this.activeTab = "threads";
      if (id) {
        this.ticket_loading = true;
        this.action = "update";
        this.$axios
          .get("tickets/" + id)
          .then((res) => {
            this.ticket = res.data.ticket;
            this.updateTitle(
              this.$i18n.tc("ticket", 2) + ": #" + this.ticket.id
            );
            this.$bus.$emit("update-selected-ticket", { id: this.ticket.id });
            this.options.ticket_id = this.ticket.id;
            this.tickets = res.data.ticket.customer_history;
            this.activities = res.data.ticket.activities;
            this.trackingNums = res.data.ticket.shippings;
            let list = res.data.ticket.activities.list.reduce((arr, obj) => {
              let array = [];
              if (obj.pack_id)
                array.push(
                  `<span class="has-text-weight-semibold">${this.$tc(
                    "pack",
                    1
                  )}</span>: ${obj.pack_id}`
                );
              if (obj.prod_id)
                array.push(
                  `<span class="has-text-weight-semibold">${this.$tc(
                    "product",
                    1
                  )}</span>: ${obj.prod_id}`
                );
              if (obj.prod_ref_id)
                array.push(
                  `<span class="has-text-weight-semibold">${this.$tc(
                    "sku",
                    1
                  )}</span>: ${obj.prod_ref_id}`
                );
              if (obj.inventory_id)
                array.push(
                  `<span class="has-text-weight-semibold">${this.$t(
                    "inventory"
                  )}</span>: ${obj.inventory_id}`
                );
              if (obj.voucher_id)
                array.push(
                  `<span class="has-text-weight-semibold">${this.$t(
                    "voucher"
                  )}</span>: ${obj.voucher_id}`
                );

              if (
                typeof obj.details == "object" &&
                Object.prototype.toString.call(obj.details) !=
                  "[object Array]" &&
                obj.details != null
              ) {
                for (const [key, value] of Object.entries(obj.details)) {
                  const valuesToCheck = ["User", "Order", "Ticket"];
                  if (!valuesToCheck.includes(key)) {
                    array.push(
                      `<span class="has-text-weight-semibold">${this.$tc(
                        key,
                        1
                      )}</span>: ${JSON.stringify(value)
                        .split('"')
                        .join("")
                        .split(",")
                        .join(", ")}`
                    );
                  }
                }
              } else if (
                typeof obj.details == "object" &&
                Object.prototype.toString.call(obj.details) ==
                  "[object Array]" &&
                obj.details != null
              ) {
                array.push(
                  JSON.stringify(obj.details)
                    .split('"')
                    .join("")
                    .split(",")
                    .join(", ")
                );
              } else if (obj.details != null) {
                array.push(obj.details);
              }
              obj.details = array.join(" - ");

              return [...arr, obj];
            }, []);
            this.activities.list = list;
            this.colors = res.data.filters.colors;
            this.tags = res.data.filters.tags;
            this.priorities = res.data.filters.priorities;
            this.carriers = res.data.filters.carriers;
            this.threads = res.data.ticket.threads;
            this.forms = res.data.forms;
            this.editorConfig.placeholderText =
              res.data.forms.reply.fields.thread.placeholder;
            this.attachments = res.data.ticket.attachments;
            this.attachments.list = [];
            for (let i = 0; i < this.threads.list.length; i++) {
              for (
                let j = 0;
                j < this.threads.list[i].attachments.length;
                j++
              ) {
                let att = this.threads.list[i].attachments[j];
                att.internal = this.threads.list[i].internal.value;
                att.agent = this.threads.list[i].agent;
                att.created_at = this.threads.list[i].created_at;
                this.attachments.list.push(att);
              }
            }
            this.followers = res.data.ticket.followers || [];
            this.selectedTags = res.data.ticket.tags || [];
            this.subject = res.data.ticket.subject.id;
            this.priority = res.data.ticket.priority;
            this.assigned_to =
              (res.data.ticket.agent && res.data.ticket.agent.id) || null;
            this.status = res.data.ticket.status.id;
            if (this.ticket.carrier) this.carrier = this.ticket.carrier;
            this.$bus.$emit("update-seen", {
              id: this.ticket.id,
            });
            
            //scroll to latest thread
            let index = this.threads.list.length - 1;
            if (index != -1) {
              const threadId = "reply-field";
              const checkElement = () => {
                const threadElement = document.getElementById(threadId);
                if (threadElement) {
                  const rect = threadElement.getBoundingClientRect();
                  const stickySectionHeight = 0;
                  const viewportHeight =
                    window.innerHeight || document.documentElement.clientHeight;

                  const inViewport =
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= viewportHeight - stickySectionHeight &&
                    rect.right <=
                      (window.innerWidth ||
                        document.documentElement.clientWidth);

                  if (!inViewport) {
                    threadElement.scrollIntoView({
                      behavior: "instant",
                      block: "start",
                      inline: "start",
                    });
                  }
                } else {
                  setTimeout(checkElement, 100);
                }
              };
              checkElement();
            }
          })
          .catch((e) => {
            this.clientError(e);
            this.$emit("close");
          })
          .finally(() => (this.ticket_loading = false));
      }
      this.subjects = this.options.subjects;
      this.statuses = this.options.statuses;
      this.filteredAgents = this.agents.filter((l) => {
        return !l.selected;
      });
      if (this.selectedTags.length > 0) {
        this.filteredTags = this.tags.filter(
          (l) => this.selectedTags.findIndex((s) => s.id == l.id) == -1
        );
      } else {
        this.filteredTags = this.tags;
      }
    },
    async showThread() {
      this.show_thread_field = true;
      setTimeout(() => {
        this.focusEditor();
        var input = document.getElementsByClassName("fr-element fr-view")[0];
        input.scrollIntoView({
          behavior: "instant",
          block: "start",
          inline: "start",
        });
      }, 300);
    },
    focusEditor() {
      var input = document.getElementsByClassName("fr-element fr-view")[0];
      input.focus();
    },
    OpenEditThread(msg) {
      this.show_thread_edit = msg.id;
      this.editedThread = msg.thread;
    },
    editThread() {
      this.edit_loading = true;
      const formData = new FormData();
      formData.append("thread", this.editedThread);
      if (this.images) {
        for (let j = 0; j < this.images.length; j++) {
          formData.append(`files[${j}]`, this.images[j]);
        }
      }
      formData.append("action", "update");
      formData.append("_method", "PUT");

      this.$axios({
        method: "post",
        url: "tickets/" + this.ticket_id + "/threads/" + this.show_thread_edit,
        data: formData,
        headers: { "content-type": "multipart/form-data" },
      })

        .then((res) => {
          this.$root.notify(this.$t("successfully_updated"), "is-success");
          let index = this.threads.list.findIndex(
            (m) => m.id == res.data[0].id
          );
          this.threads.list[index] = res.data[0];
          this.show_thread_edit = null;
          this.threadUpdated = index;
          setTimeout(() => {
            this.threadUpdated = -1;
          }, 3000);
        })
        .catch((e) => this.clientError(e))
        .finally(() => (this.edit_loading = false));
    },
    async submitValidateClaim() {
      let allValid = true;
      for (let i = 0; i < this.ticket.products.list.length; i++) {
        const isValid = await this.$refs.parentObserver.validate()
        if (!isValid) {
          allValid = false;
        }
      }

      if (allValid) {
        this.validateProducts();
      }
    },
    validateProducts() {
      this.showProductConfirmation = true;
    },
    cancelClaim() {
      this.showProductConfirmation = false;
    },
    confirmClaim() {
      let list = [];
      for (let i = 0; i < this.ticket.products.list.length; i++) {
        if (this.ticket.products.list[i].status != 'refunded') {
          let obj = {
            ticket_id: this.ticket.id,
            prod_ref_id: this.ticket.products.list[i].id,
            qty: this.ticket.products.list[i].qty.selected,
            status: this.ticket.products.list[i].status,
            notes: this.ticket.products.list[i].notes,
          };
          list.push(obj);
        }
        else {
          for (let j = 0; j < this.ticket.products.list[i].item_ids.length; j++) {
            if (this.ticket.products.list[i].item_ids[j].qty.selected) {
              let obj = {
                ticket_id: this.ticket.id,
                prod_ref_id: this.ticket.products.list[i].id,
                qty: this.ticket.products.list[i].item_ids[j].qty.selected,
                status: this.ticket.products.list[i].status,
                notes: this.ticket.products.list[i].notes,
                linked_item_id: this.ticket.products.list[i].item_ids[j].id,
              };
              list.push(obj);
            }
          } 
        }
      }
      this.productsLoading = true;
      this.$axios
        .put("tickets/" + this.ticket_id + "/items", {
          products: list,
        })
        .then((res) => {
          this.showProductConfirmation = false;
          this.ticket.products = res.data.products;
          this.$root.notify(this.$t("successfully_updated"), "is-success");
        })
        .catch((e) => this.clientError(e))
        .finally(() => (this.productsLoading = false));
    },
    carrierHasApi(c) {
      if (c) {
        let index = this.carriers.findIndex((item) => item.id == c);
        return this.carriers[index].api;
      } else return false;
    },
    showReturnLabel() {
      this.editedTrackingNums = JSON.parse(JSON.stringify(this.trackingNums));
      if (
        this.editedTrackingNums.length == 0 ||
        (this.editedTrackingNums.length &&
          this.editedTrackingNums[this.editedTrackingNums.length - 1]
            .tracking_number)
      ) {
        let obj = {
          id: null,
          tracking_number: null,
          carrier: null,
          type: "return",
        };
        this.editedTrackingNums.push(obj);
      }
      this.showShippedModal = true;
    },
    setTracking() {
      if (
        this.editedTrackingNums[this.editedTrackingNums.length - 1]
          .tracking_number ||
        this.editedTrackingNums[this.editedTrackingNums.length - 1].carrier
      ) {
        let obj = {
          id: null,
          carrier: null,
          tracking_number: null,
          type: "return",
        };
        this.editedTrackingNums.push(obj);
      }
    },
    generateReturnLabel() {
      this.generateLabelLoading = true;
      let data = {
        shippings: JSON.parse(
          JSON.stringify(this.editedTrackingNums.filter((t) => t.carrier))
        ),
      };
      this.$axios
        .post("tickets/" + this.ticket_id + "/return-label", data)
        .then((res) => {
          this.show_thread_field = true;
          //fill reply field
          this.fillReturnLabel(res.data);
          //download return label
          if (res.data.return_label && res.data.return_label.attachments) {
            for (let i = 0; i < res.data.return_label.attachments.length; i++) {
              const downloadLink = document.createElement("a");
              downloadLink.href = res.data.return_label.attachments[i].content;
              downloadLink.download = res.data.return_label.attachments[i].name;
              downloadLink.click();
            }
          }
          if (res.data.shippings) this.trackingNums = res.data.shippings;
          this.showShippedModal = false;
        })
        .catch((e) => this.clientError(e))
        .finally(() => (this.generateLabelLoading = false));
    },
    fillReturnLabel(data) {
      if (data.return_label.attachments)
        this.images = data.return_label.attachments;
      this.thread = data.return_label.thread;
    },
    generateOrder() {
      this.$buefy.dialog.confirm({
        message: this.$t("are_you_sure"),
        type: "is-info",
        // hasIcon: true,
        // trapFocus: true,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        onConfirm: () => {
          this.generateOrderLoading = true;
          this.$axios
            .get("tickets/" + this.ticket_id + "/new-order")
            .then((res) => {
              this.ticket.new_order_id = res.data.new_order_id;
              this.ticket.products = res.data.products;
              this.generateOrderLoading = false;
            })
            .catch((e) => this.clientError(e))
            .finally(() =>
              this.openDrawer("orderForm", {
                key: this.ticket.new_order_id,
                order_id: this.ticket.new_order_id,
                user_id: this.ticket.user.id,
                site_id: this.ticket.site.id,
                warehouse_id: 1,
                warehouses: this.warehouses,
              })
            );
        },
      });
    },
    confirmDeleting(att, msg) {
      this.showDeleteAttachment = true;
      this.deleteAtt = att;
      this.deleteAttMsg = msg;
    },
    deleteAttachment() {
      let msg = this.deleteAttMsg;
      let att = this.deleteAtt;
      this.$axios
        .delete(
          "tickets/" + this.ticket_id + "/threads/" + msg.id + "/" + att.label
        )
        .then(() => {
          this.$root.notify(this.$t("successfully_deleted"), "is-success");

          //remove attachment from the thread
          let index = this.threads.list.findIndex((a) => a.id == msg.id);
          let attIndex = this.threads.list[index].attachments.findIndex(
            (a) => a.label == att.label
          );
          this.threads.list[index].attachments.splice(attIndex, 1);

          //remove attachment from attachments tab
          let i = this.attachments.list.findIndex((a) => a.label == att.label);
          this.attachments.list.splice(i, 1);

          this.showDeleteAttachment = false;
        })
        .catch((e) => this.clientError(e));
    },
    cancelDelete() {
      this.showDeleteAttachment = false;
      this.deleteAtt = null;
      this.deleteAttMsg = null;
    },
    downloadAll(attachments, msg_id) {
      for (let i = 0; i < attachments.length; i++) {
        this.download(attachments[i], msg_id);
      }
    },
    download(att, msg_id) {
      this.$axios
        .get(
          "tickets/" +
            this.ticket_id +
            "/threads/" +
            msg_id +
            "?filename=" +
            att.label,
          {
            headers: {
              Accept: "*",
            },
            responseType: "blob",
          }
        )
        .then((res) => {
          var filename = att.label;
          var blob = new Blob([res.data]);
          var link = window.document.createElement("a");

          link.href = window.URL.createObjectURL(blob, {
            type: res.headers["content-type"]
              ? res.headers["content-type"]
              : res.headers["Accept"],
          });

          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((e) => this.clientError(e));
    },
    markUnread(msg) {
      this.$axios
        .put("tickets/" + this.ticket_id + "/threads/" + msg.id, {
          action: "unread",
        })
        .then((res) => {
          this.$bus.$emit("update-tickets-list", {
            id: this.ticket_id,
            ticket: res.data,
          });
          this.$root.notify(this.$t("successfully_updated"), "is-success");
        })
        .catch((e) => this.clientError(e));
    },
    openScheduleModal() {
      this.scheduleModal = true;
    },
    checkCollision(close) {
      this.sendAndClose = close;
      this.$axios
        .get("tickets/" + this.ticket_id + "/check")
        .then((res) => {
          if (res.data.collision) {
            this.showCollisionModal = true;
            this.collision = res.data.collision;
          } else {
            this.sendThread(this.sendAndClose);
          }
        })
        .catch((e) => this.clientError(e))
        .finally(() => (this.ticket_loading = false));
    },
    sendThread(close) {
      this.sendLoading = true;
      // this.cancelled = false;
      // this.$buefy.snackbar.open({
      //   duration: 5000,
      //   message: this.$t("thread_sent_successfully"),
      //   position: "is-top",
      //   type: "is-success",
      //   actionText: this.$t("undo"),
      //   onAction: () => {
      //     this.cancel();

      //     this.focusEditor();
      //   },
      // });

      // this.timeoutId = setTimeout(() => {
      //   if (!this.cancelled) {
          this.focusEditor();
          const formData = new FormData();
          formData.append("thread", this.thread);
          if (this.tracking_number)
            formData.append("tracking_number", this.tracking_number);
          formData.append("internal", this.internal ? 1 : 0);
          //   if (force) formData.append("force_sending", force ? 1 : 0);
          if (this.images) {
            for (let j = 0; j < this.images.length; j++) {
              formData.append(`files[${j}]`, this.images[j]);
            }
          }
          this.$axios({
            method: "post",
            url: "tickets/" + this.ticket_id + "/reply",
            data: formData,
            headers: { "content-type": "multipart/form-data" },
          })

            .then((res) => {
              //   this.thread = null;
              //   this.internal = false;
              //   this.threads.list.push(res.data.thread);

              this.$bus.$emit("update-tickets-list", {
                id: this.ticket_id,
                ticket: res.data,
              });

              this.threads = res.data.threads;
              this.assigned_to = res.data.agent.id;
              this.status = res.data.status.id;
              this.thread = "";
              this.internal = false;
              this.renderUploader = false;
              this.images = [];
              setTimeout(() => {
                this.renderUploader = true;
              }, 200);
              this.threadUpdated = res.data.threads.list.length - 1;
              setTimeout(() => {
                this.threadUpdated = -1;
              }, 2000);

              if (close) {
                let solved_status_id = 4;
                this.status = solved_status_id;
                this.updateOptions();
              }
            })
            .catch((e) => this.clientError(e))
            .finally(() => (this.sendLoading = false));
        // }
      // }, 5000);
    },
    cancel() {
      this.cancelled = true;
      this.sendLoading = false;
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
    },
    selectColor(col) {
      this.newTag.background = col.background;
      this.newTag.color = col.color;
      this.newTag.border = col.border;
    },
    addNewTag() {
      this.new_tag_loading = true;
      let url = "tags";
      let method = "POST";
      let formData = {
        tag: this.newTag.tag,
        background: this.newTag.background,
        color: this.newTag.color,
        border: this.newTag.border,
        type: "ticket",
      };
      this.$axios({
        method: method,
        url: url,
        data: formData,
      })
        .then((res) => {
          this.$root.notify(this.$t("successfully_created"), "is-success");
          this.tags.push(res.data);
          this.selectedTags.push(res.data);
          this.showAddTag = false;
        })
        .catch((e) => this.$root.clientError(e))
        .finally(() => (this.new_tag_loading = false));
    },
    updateOptions() {
      this.options_loading = true;
      this.$axios
        .put(`/tickets/` + this.ticket_id, {
          followers: this.followers.map((follower) => follower.id),
          tags: this.selectedTags.map((tag) => tag.id),
          subject_id: this.subject,
          priority: this.priority.key,
          agent_id: this.assigned_to,
          status_id: this.status,
        })
        .then((res) => {
          this.$bus.$emit("update-tickets-list", {
            id: this.ticket_id,
            ticket: res.data,
          });
          this.$root.notify(this.$t("successfully_updated"), "is-success");
        })
        .catch((e) => this.$root.clientError(e))
        .finally(() => (this.options_loading = false));
    },
    getFilteredAgents(text) {
      if (typeof text == "object") text = "";
      let filteredAgents = this.agents.filter((option) => {
        return (
          option.name.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
        );
      });
      this.filteredAgents = filteredAgents.filter(
        (l) => this.followers.findIndex((s) => s.id == l.id) == -1
      );
    },
    getFilteredTags(text) {
      if (typeof text == "object") text = "";
      let filteredTags = this.tags.filter((option) => {
        return (
          option.tag.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
        );
      });
      this.filteredTags = filteredTags.filter(
        (l) => this.selectedTags.findIndex((s) => s.id == l.id) == -1
      );
      this.newTag.tag = text;
    },
    openSlides(view, msg, index) {
      this.current_view = view;
      this.window = index;
      this.ticket_slides = [];
      let slides = msg
        ? msg.attachments.filter((t) => t.type === "image")
        : this.attachments.list.filter((t) => t.type === "image");
      this.ticket_slides = slides;

      this.slides = true;
      this.slides_content = () =>
        import("@/components/modules/FullScreenImage");
    },
  },
  filters: {
    truncate(value, length) {
      return value.length > length ? value.substr(0, length) + "..." : value;
    },
  },
};
</script>
	  